@use "sass:math";

@import "~@mdn/minimalist/sass/vars/layout";
@import "~@mdn/minimalist/sass/vars/typography";

.page-not-found {
  p {
    margin-top: 50px;
  }

  .fallback-document {
    p {
      margin-top: math.div($base-spacing, 2);
    }

    .fallback-link {
      font-size: $small-medium-font-size;
    }
  }
}
