@use "sass:math";

@use "~@mdn/minimalist/sass/vars/layout" as *;
@use "~@mdn/minimalist/sass/vars/mdn-web-docs-palette" as *;
@use "~@mdn/minimalist/sass/vars/typography" as *;

.header-search {
  background-color: #fff;

  @media #{$mq-tablet-and-up} {
    height: 40px;
    min-width: 80px;
    width: inherit;
  }

  .search-widget {
    margin: $base-spacing 0;
    position: relative;
    width: 100%;

    @media #{$mq-tablet-and-up} {
      margin: math.div($base-spacing, 2) 0;
      min-width: 80px;
    }

    @media #{$mq-large-desktop-and-up} {
      margin: 0;
    }
  }

  input.search-input-field {
    border: 2px solid $text-color;
    color: $text-color;
    min-width: 60px;
    padding: $base-unit ($base-unit * 2);
    width: 100%;
  }

  .server-error {
    color: $mdn-red700;
    font-size: $small-font-size;
    margin: 0;
  }

  .search-results {
    background-color: $white;
    border: 2px solid $mdn-neutral900;
    border-radius: 0 0 $base-unit $base-unit;
    border-top: 0;
    box-shadow: 0 2px 8px 0 $mdn-neutral500;
    left: 0;
    position: absolute;
    top: 42px;
    width: 100%;
    z-index: $top-layer;

    .indexing-warning {
      color: $mdn-yellow400;
    }

    div {
      padding: $base-unit;
    }

    .nothing-found {
      font-style: italic;
    }

    .fuzzy-engaged {
      font-size: $tiny-text;
    }

    .result-item {
      border-bottom: 1px solid $mdn-neutral100;
      word-break: break-word;
    }

    .result-item:hover,
    .result-item.highlight {
      background-color: $mdn-neutral100;
    }

    .result-item:hover,
    .result-item:focus {
      cursor: pointer;
    }

    div:last-child {
      border-bottom: 0;
    }

    mark {
      background-color: $mdn-yellow200;
    }

    small {
      overflow-wrap: break-word;
    }

    a {
      text-overflow: ellipsis;
    }

    div.searchindex-error {
      color: $mdn-red700;
    }
  }
}
