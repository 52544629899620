@use "sass:math";

.bc-history-link {
  display: none;

  @media #{$mq-tablet-and-up} {
    background: transparent url("~@mdn/dinocons/arrows/triangle.svg") center
      center no-repeat;
    background-size: 16px;
    border: 0;
    border-bottom: 1px solid $mdn-neutral900;
    border-radius: 0;
    box-shadow: none;
    color: $mdn-neutral900;
    cursor: pointer;
    display: block;
    font-size: $tiny-text;
    height: 16px;
    padding: math.div($base-spacing, 2);
    position: relative;
    transform: rotate(180deg);
    width: 100%;
  }

  @media #{$mq-small-desktop-and-up} {
    bottom: 0;
    left: 0;
    margin: math.div($base-spacing, 2) 0 0;
    padding: 0;
    position: absolute;
    z-index: $top-layer;
  }
}

.bc-history-link-inverse {
  border: 0;
  border-top: 1px solid $mdn-neutral900;
  transform: rotate(0deg);
}
