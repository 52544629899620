@use "sass:math";

@use "~@mdn/minimalist/sass/vars/mdn-web-docs-palette" as *;
@use "~@mdn/minimalist/sass/vars/layout" as *;
@use "~@mdn/minimalist/sass/vars/typography" as *;

.sidebar {
  font-size: $small-font-size;
  padding: $base-spacing;

  h4 {
    font-family: $heading-font-family;
    font-size: $medium-font-size;
    margin-bottom: $base-spacing;
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    ol,
    ul {
      @media #{$mq-tablet-and-up} {
        padding-left: math.div($base-spacing, 2);
      }
    }
  }

  summary,
  li {
    margin-bottom: math.div($base-spacing, 2);
  }

  a {
    hyphens: auto;
  }

  code {
    background-color: transparent;
    white-space: normal;
    word-wrap: break-word;
  }

  summary {
    cursor: pointer;
  }

  details {
    margin: $base-spacing 0;
  }

  .icon {
    background-size: 18px;
    height: 18px;
    width: 18px;
  }

  /* icons in sidebars */
  .icon-experimental,
  .icon-nonstandard {
    color: $mdn-blue700;
  }

  .icon-deprecated {
    color: $mdn-red700;
  }
}
