@use "sass:math";

@import "~@mdn/minimalist/sass/vars/mdn-web-docs-palette";
@import "~@mdn/minimalist/sass/vars/layout";
@import "~@mdn/minimalist/sass/vars/typography";

.page-footer-logo {
  margin-bottom: $base-spacing;
}

.page-footer {
  background-color: $mdn-neutral900;
  color: $text-color-inverted;
  padding: ($base-spacing * 2) $base-spacing;
  position: relative;

  .content-container {
    margin: 0 auto;
    max-width: 1400px;
  }

  a {
    color: $text-color-inverted;
  }

  ul {
    a {
      display: block;
      padding: 10px 0;
      width: fit-content;
    }
  }

  .social {
    align-items: center;
    display: flex;

    h4 {
      font-size: $small-medium-font-size;
      font-weight: normal;
      margin: inherit;
      margin-right: 10px;
    }

    li {
      display: inline-block;
      margin-right: 10px;
    }

    ul {
      padding-inline-start: math.div($base-spacing, 4);
    }
  }

  .social-icon::before {
    background-size: 18px;
    content: "";
    display: inline-block;
    filter: invert(1);
    height: 18px;
    vertical-align: sub;
    width: 18px;
  }

  .twitter::before {
    background: transparent url("~@mdn/dinocons/social/twitter.svg") 0 0
      no-repeat;
  }

  .github::before {
    background: transparent url("~@mdn/dinocons/brands/github-mark-small.svg") 0
      0 no-repeat;
  }

  .instagram::before {
    background: transparent url("~@mdn/dinocons/social/instagram.svg") 0 0
      no-repeat;
  }

  .footer-license {
    margin: 10px 0;

    @media #{$mq-tablet-and-up} {
      border-top: 1px solid $mdn-neutral100;
      margin: 50px 0 30px;
      padding: 30px 0 0;
    }
  }

  .footer-legal {
    @media #{$mq-tablet-and-up} {
      padding-bottom: 20px;
    }

    li {
      display: inline-block;
    }

    a {
      display: block;
      padding: 0 20px 0 0;
    }
  }
}

@supports (display: grid) {
  .content-container {
    display: grid;

    .page-footer-logo,
    .link-list-mdn,
    .link-list-moz,
    .footer-license,
    .footer-legal {
      grid-column: 1/3;
    }

    .page-footer-logo {
      grid-row: 1/2;
    }

    .link-list-mdn {
      grid-row: 2/3;

      @media #{$mq-tablet-and-up} {
        grid-column: 1/2;
      }
    }

    .link-list-moz {
      grid-row: 3/4;

      @media #{$mq-tablet-and-up} {
        grid-column: 2/3;
        grid-row: 2/3;
      }
    }

    .social-mdn {
      grid-column: 1/2;
      grid-row: 4/5;
    }

    .social-moz {
      grid-column: 2/3;
      grid-row: 4/5;
    }

    .footer-license {
      grid-row: 6/7;
    }

    .footer-legal {
      grid-row: 7/8;
    }
  }
}
