@use "sass:math";

.bc-legend {
  margin: ($base-spacing * 2) 0;

  dl.bc-legend-items-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: $base-spacing * 2;
    line-height: 1.2;
    max-width: inherit;

    .bc-legend-item {
      flex-basis: 100%;

      @media #{$mq-tablet-and-up} {
        flex-basis: 45%;
      }
    }

    dt,
    dd {
      display: inline-block;
      margin: 0;
      max-width: 275px;
      min-height: 30px;
      min-width: 30px;
    }

    dt {
      margin-right: math.div($base-spacing, 2);
      vertical-align: bottom;
    }
  }

  abbr {
    cursor: help;
    display: inline-block;
  }

  .bc-supports {
    display: inline-block;
    min-height: 30px;
    min-width: 30px;
  }
}
