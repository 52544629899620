@import "~@mdn/minimalist/sass/vars/mdn-web-docs-palette";
@import "~@mdn/minimalist/sass/vars/layout";

$menu-box-shadow: 0 2px 8px 0 $mdn-neutral500;
$menu-border: solid 1px $mdn-neutral400;
$menu-border-radius: 4px;

.dropdown-container {
  position: relative;

  .dropdown-menu-label {
    align-items: center;
    border: 0;
    color: $text-color;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 0;
    white-space: nowrap;

    &:active {
      background-color: transparent;
    }
  }

  .dropdown-menu-items {
    background-color: $white;
    display: none;
    flex-direction: column;
    margin: ($base-unit * 2) 0;
    min-width: 100%;

    @media #{$mq-tablet-and-up} {
      border: $menu-border;
      border-radius: $menu-border-radius;
      box-shadow: $menu-box-shadow;
      position: absolute;
      right: 0;
      z-index: $top-layer;
    }

    a,
    button {
      color: $mdn-neutral900;
      display: inline-block;
      padding: $base-unit ($base-unit * 2);
      /* buttons have centered text by default */
      text-align: left;
      white-space: nowrap;
      width: 100%;

      &:hover,
      &:focus {
        background-color: $mdn-blue600;
        color: $white;
        text-decoration: none;
      }
    }

    .signout-button {
      text-decoration: none;
    }
  }

  .show {
    display: block;
  }
}
