@import "~@mdn/minimalist/sass/vars/mdn-web-docs-palette";
@import "~@mdn/minimalist/sass/vars/layout";

.interactive {
  background-color: $mdn-neutral100;
  border: 1px solid $mdn-neutral200;
  color: $text-color;
  padding: 10px;
  width: 100%;
  // Note! The height is set as a regular pixel height number
  // on the <iframe> itself and that's done in the EmbedInteractiveExample.ejs
  // macro.
}
