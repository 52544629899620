/* The document grid */
@supports (display: grid) {
  .page-wrapper.document-page {
    grid-template-columns: 70% 30%;

    @media #{$mq-small-desktop-and-up} {
      grid-template-columns: minmax(25%, 75%) minmax(75%, 25%);
      grid-template-rows: repeat(3, auto) max-content 1fr;
    }
  }

  .document-page {
    .page-header,
    .generic-loading,
    .breadcrumb-locale-container,
    .localized-content-note,
    .document-toc-container,
    .main-content,
    .sidebar,
    .metadata {
      grid-column: 1/3;
    }

    .page-header {
      grid-row: 1/2;
    }

    .breadcrumb-locale-container {
      grid-row: 2/3;
    }

    .localized-content-note {
      grid-row: 3/4;
    }

    .document-toc-container {
      grid-row: 4/5;

      @media #{$mq-small-desktop-and-up} {
        grid-column: 1/2;
      }
    }

    .main-content {
      grid-row: 5/6;

      @media #{$mq-small-desktop-and-up} {
        grid-column: 2/3;
        grid-row: 4/6;
      }
    }

    .sidebar {
      grid-row: 6/7;

      @media #{$mq-small-desktop-and-up} {
        grid-column: 1/2;
        grid-row: 5/6;
      }
    }

    .metadata {
      grid-row: 7/8;
    }
  }
}
