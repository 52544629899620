@import "~@mdn/minimalist/sass/mixins/utils";
@import "~@mdn/minimalist/sass/vars/layout";

.home-content-container {
  margin: 0 auto;
  max-width: $max-width-default;
  padding: $base-spacing;
  position: relative;

  @media #{$mq-small-desktop-and-up} {
    @include gap($base-spacing);
    display: flex;
    margin: ($base-spacing * 2) auto;
  }
}
