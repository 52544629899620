@import "~@mdn/minimalist/sass/mixins/utils";

@import "./atoms/bc-github";
@import "./atoms/bc-table";
@import "./atoms/bc-platforms";
@import "./atoms/bc-icons";
@import "./atoms/bc-supports";
@import "./atoms/bc-browsers";
@import "./atoms/bc-history";
@import "./atoms/bc-history-link";
@import "./atoms/bc-legend";

.offscreen,
.only-icon span {
  @include visually-hidden();
}

.bc-table-row-header {
  code {
    overflow: hidden;
  }

  .left-side,
  .right-side {
    white-space: pre;
    overflow: hidden;
  }

  /* Can only flex-shrink and not flex-grow 
  ie the "slider" in a sliding glass door */
  .left-side {
    flex: 0 1 auto;
    text-overflow: ellipsis;
  }
  /* Can flex-grow and not flex-shrink as
  its the stationary portion */
  .right-side {
    flex: 1 0 auto;
  }
}
