@import "~@mdn/minimalist/sass/vars/layout";

.bc-github-link {
  margin: $base-unit 0;

  @media #{$mq-small-desktop-and-up} {
    float: right;
    white-space: pre-line;
  }
}
